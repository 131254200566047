import React from "react";

import DocumentationPageLayout from "@src/components/DocumentationPageLayout";

function Security({ pageContext }) {
  return (
    <DocumentationPageLayout
      content={pageContext.security}
      title="Security"
      seoTitle="page_titles.security"
      seoDescription="page_descriptions.security"
      type="security"
    />
  );
}

export default Security;
